export default function StatusHeader({monitors, lastUpdate}) {
  var serviceStatuses = Object.keys(monitors).map((key) => monitors[key].lastStatus)
  var systemStatus = serviceStatuses.reduce((accumulator, currentValue) => accumulator && currentValue === "success", true)
  
  var text
  var color

  if (!lastUpdate) {
    text = "Fetching Status Data"
    color = "grey"
  } else if (systemStatus) {
    text = "All Systems Operational"
    color = "green"
  } else {
    text = "Experiencing Issues"
    color = "red"
  }

  var style = {
    "backgroundColor": color
  }

  var date = new Date(lastUpdate)

  var lastUpdateTime = `${date.toLocaleTimeString()}`

  return (
    <div className="StatusHeader" style={style}>
      <div>{text}</div>
      { lastUpdate ? <div>Updated at {lastUpdateTime}</div> : null }
    </div>
  )
}