import StatusCard from "./statusCard"
import { useState } from 'react';

export default function TabbedMonitorList({services}) {
  const defaultRegion = "global" 
  const [activeRegion, setRegion] = useState(defaultRegion)

  function handleRegionClick(region) {
    setRegion(region)
  }

  var regions = []

  for (var service in services) {
    var r = services[service].subenvironment

    if (!(regions.includes(r))) {
      regions.push(r)
    }
  }

  var activeServices = []

  for (var service in services) {
    if (services[service].subenvironment === activeRegion) {
      activeServices.push(services[service])
    }
  }

  function getRegionName(region) {
    switch (region) {
      case "global":
        return "Global"
      case "dev":
        return "Development"
      case "prod":
        return "Europe, Middle East, Africa"
      case "us":
        return "Americas"
      case "apac":
        return "Asia Pacific"
      default:
        return region
    }
  }

  return (
    <div className="MonitorList">
      <div className="RegionTabButtons">
        {
          regions.map((region) => {
            return <button className={region === activeRegion ? "RegionButtonActive" : "RegionButtonInactive"} key={region} onClick={() => {
              handleRegionClick(region)
            }}>{getRegionName(region)}</button>
          })
        }
      </div>
      <div className="Services">
        {
          activeServices.map(service => <StatusCard data={service}/>)
        }
      </div>
    </div>
  )

}