export async function get_data(service) {
  var request_url = `/prometheus/get-status-history/${service}`

  var response = await fetch(request_url)
  var status_data = await response.json()
  var lastUpdateTime = new Date(response.headers.get("x-cache-date"))

  var services_status = []

  if (status_data.data.result.length == 0 ) {
    status_data.data.result = [{
      values: [],
      metric: {}
    }]
  }
  
  for (let i=0; i < status_data.data.result.length; i++) {
    var incidents = {}
    var data = status_data.data.result[i]
    
    // default labels if none exist
    var metadata = {
      subenvironment: "global",
      service: service
    }

    for (let j=0; j < Object.keys(data.metric).length; j++) {
      var key = Object.keys(data.metric)[j]
      metadata[key] = data.metric[key]
    }

    var lastStatus = "success"

    // iterate over query result and count incidents grouped by day
    for (let k = 0; k < (status_data.data.result[i].values.length ?? 0); k++) {  

      var date = get_day_from_epoch(status_data.data.result[i].values[k][0])

      if (status_data.data.result[i].values[k][1] > 0.2) {
        if (date in incidents) {
          incidents[date] += 1
        } else {
          incidents[date] = 1
        }
        lastStatus = "fail"
      } else {
        lastStatus = "success"
      }
    }

    // create an array for the status history and apply incident count
    var history = Array.from(Array(parseInt(process.env.REACT_APP_HISTORY_LENGTH)).keys()).map(
      (day, index) => {
        var past = process.env.REACT_APP_HISTORY_LENGTH - index
        var d = new Date()
        d.setDate(d.getDate() - past + 1)
        
        var incident_count = incidents[d.toISOString().split('T')[0]] ?? 0
        
        return {
          date: d.toISOString().split('T')[0],
          incident_count: incident_count
        }
      }
    )

    // merge metadata object into status data
    var data = {
      ...{
        history: history,
        lastStatus: lastStatus,
        lastUpdateTime: lastUpdateTime
      }, 
      ...metadata
    }

    services_status.push(data)
  }

  return services_status
}

function get_day_from_epoch(epoch_time) {
  var d = new Date(epoch_time*1000)
  return d.toISOString().split('T')[0]
}

export async function get_all_status_data() {
  var identity = get_data("identity")
  var apis = get_data("api")
  var subman = get_data("subman")
  var ui = get_data("ui")

  let [identity_data, apis_data, subman_data, ui_data] = await Promise.all([identity, apis, subman, ui])

  var lastUpdateTime = new Date(0)

  var result = [identity_data, apis_data, subman_data, ui_data].flat()

  for (let i=0; i<result.length; i++) {
    if (result[i].lastUpdateTime > lastUpdateTime) {
      lastUpdateTime = result[i].lastUpdateTime
    }
  }

  return {
    lastUpdate: lastUpdateTime,
    services: [identity_data, apis_data, subman_data, ui_data].flat()
  }
}