import './App.css';
import StatusHeader from './components/statusHeader';
import TabbedMonitorList from './components/tabbedMonitorList';
import { get_all_status_data } from "./prometheus"
import { useState } from 'react';
import { useEffect } from 'react';

function App() {
  const [status_data, setData] =  useState({
    "services": [],
    "lastUpdate": null
  })

  useEffect(() => {
    let ignore = false
    get_all_status_data().then((data) => {
      if (!ignore) {
        setData(data)
      }
    })
    return () => {
      ignore = true
    }
  }, []) 

  return (
    <div className="App">
      <header className="Title">
        Status Page
      </header>
      <StatusHeader monitors={status_data.services} lastUpdate={status_data.lastUpdate}/>
      { status_data.services.length > 0 ? <TabbedMonitorList services={status_data.services} /> : null }
    </div>
  );
}

export default App;
