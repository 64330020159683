import StatusPill from "./statusPill"

export default function StatusHistogram({data}) {
  return (
    <span className="StatusHistogram">
      {
        data.map(
          day => <StatusPill data={day}/>
        )
      }
    </span>
  )
}