import StatusHistogram from "./statusHistogram"

export default function StatusCard({data}) {
  const historyLength = process.env.REACT_APP_HISTORY_LENGTH

  var color = "white"
  var statusText = "No Data"

  if (data.lastStatus == "success") {
    color = "green"
    statusText = "Operational"
  } else if (data.lastStatus == "fail") {
    color = "red"
    statusText = "Incident"
  }

  var style = {
    "backgroundColor": color,
    "fontSize": "0.6em",
    "borderRadius": "0.5pc",
    "padding": "5px"
  }

  function getServiceName(service) {
    switch (service) {
      case "identity":
        return "Identity Server"
      case "subman": 
        return "Subscription Manager"
      case "monolith":
        return "API"
      case "conditions":
        return "Conditions"
      case "snapshots":
        return "Snapshots"
      case "ui": 
        return "Front End"
      default:
        return service
    }
  }

  return (
    <div className="StatusCard" key={data.service}>
      <div className="StatusCardHeader">
        <div>{getServiceName(data.service)}</div>
        <div style={style}>{statusText}</div>
      </div>
      <StatusHistogram data={data.history}/>
      <div className="StatusCardFooter">
        <div>{historyLength} days ago</div>
        <div>Today</div>
      </div>
    </div>
  )
}